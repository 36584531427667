import { isValidElement } from 'react';

import { Typography } from '@mui/material';

import Box from './Box';

const TabPanel = ({ children, value, index, ...props }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`full-width-tabpanel-${index}`}
    aria-labelledby={`full-width-tab-${index}`}
    {...props}
  >
    {value === index && !isValidElement(children) && (
      <Box sx={{ p: 3 }} height="100%">
        <Typography>{children}</Typography>
      </Box>
    )}
    {value === index && isValidElement(children) && children}
  </div>
);

export default TabPanel;
