import A from './A';
import Svg from './Svg';
import ButtonBase from './ButtonBase';

const LogoMonk = ({ onClick, path }) => (
  <ButtonBase onClick={onClick} disabled={!onClick}>
    <A href={path} isPlaceholder>
      <Svg
        width="93"
        height="24"
        viewBox="0 0 93 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1632_16924)">
          <path
            d="M39.7587 0.168945C37.4371 0.168945 35.1676 0.867805 33.2372 2.17715C31.3068 3.48649 29.8023 5.3475 28.9139 7.52483C28.0255 9.70216 27.7931 12.098 28.2461 14.4094C28.6992 16.7208 29.8172 18.844 31.459 20.5103C33.1007 22.1767 35.1924 23.3114 37.4694 23.771C39.7465 24.2307 42.1067 23.9945 44.2515 23.0925C46.3964 22.1905 48.2296 20.663 49.5192 18.7034C50.8089 16.7437 51.4971 14.4399 51.4969 12.0832C51.4969 10.5185 51.1933 8.96911 50.6033 7.52353C50.0134 6.07795 49.1488 4.76448 48.0588 3.65812C46.9688 2.55176 45.6748 1.67419 44.2506 1.07552C42.8265 0.476841 41.3001 0.168787 39.7587 0.168945ZM39.7587 20.113C38.1942 20.113 36.6648 19.642 35.364 18.7597C34.0631 17.8774 33.0492 16.6233 32.4505 15.1561C31.8518 13.6888 31.6951 12.0743 32.0004 10.5167C32.3056 8.95903 33.059 7.52825 34.1652 6.40527C35.2715 5.28228 36.681 4.51752 38.2155 4.20768C39.7499 3.89785 41.3404 4.05687 42.7859 4.66463C44.2313 5.27238 45.4667 6.30158 46.3359 7.62207C47.2051 8.94257 47.669 10.495 47.669 12.0832C47.669 14.2128 46.8356 16.2552 45.3522 17.7611C43.8687 19.267 41.8567 20.113 39.7587 20.113Z"
            fill="#274B9F"
          />
          <path
            d="M39.7587 14.8034C41.2386 14.8034 42.4383 13.5856 42.4383 12.0834C42.4383 10.5811 41.2386 9.36328 39.7587 9.36328C38.2788 9.36328 37.0791 10.5811 37.0791 12.0834C37.0791 13.5856 38.2788 14.8034 39.7587 14.8034Z"
            fill="black"
          />
          <path
            d="M0 -0.000976562L11.6585 12.0775L23.5597 -0.000976576L23.5597 23.9145L19.7449 23.9145L19.6902 9.60144L11.7798 17.4464L0.0571143 5.61553L0 -0.000976562Z"
            fill="#274B9F"
          />
          <path
            d="M51.7266 -0.000976562L67.477 14.5464L67.477 -0.000976571L71.2704 -0.000976574L71.2704 23.9145L51.7266 5.88488L51.7266 -0.000976562Z"
            fill="#274B9F"
          />
          <path
            d="M92.9999 -0.000976572L87.6454 -0.000976569L76 11.9567L87.7346 23.9145L92.9999 23.9145L81.2652 11.9567L92.9999 -0.000976572Z"
            fill="#274B9F"
          />
        </g>
        <defs>
          <clipPath id="clip0_1632_16924">
            <rect
              width="93"
              height="24"
              fill="white"
              transform="translate(0 -0.000976562)"
            />
          </clipPath>
        </defs>
      </Svg>
    </A>
  </ButtonBase>
);

export default LogoMonk;
