import { Box, Text, IconCalendar } from '@drivably-apps/component-lib';
import { formatDate } from '@portal/lib';

const Date = ({ dateFormat = 'DD/MM/YY', date, preText, ...props }) => {
  const formattedDate = date ? formatDate(date, dateFormat) : '--';
  return (
    <Box isFlex direction="row" marginVertical="xs" justify="center" {...props}>
      {!preText ? (
        <IconCalendar
          width="22px"
          height="22px"
          className="date-icon"
          iconColor="#00A3E0"
        />
      ) : null}

      <Text size="xs" color="gray-text">
        {preText || ''}
        {formattedDate}
      </Text>
    </Box>
  );
};

export default Date;
