export { default as year1990 } from './1990.json';
export { default as year1991 } from './1991.json';
export { default as year1992 } from './1992.json';
export { default as year1993 } from './1993.json';
export { default as year1994 } from './1994.json';
export { default as year1995 } from './1995.json';
export { default as year1996 } from './1996.json';
export { default as year1997 } from './1997.json';
export { default as year1998 } from './1998.json';
export { default as year1999 } from './1999.json';

export { default as year2000 } from './2000.json';
export { default as year2001 } from './2001.json';
export { default as year2002 } from './2002.json';
export { default as year2003 } from './2003.json';
export { default as year2004 } from './2004.json';
export { default as year2005 } from './2005.json';
export { default as year2006 } from './2006.json';
export { default as year2007 } from './2007.json';
export { default as year2008 } from './2008.json';
export { default as year2009 } from './2009.json';

export { default as year2010 } from './2010.json';
export { default as year2011 } from './2011.json';
export { default as year2012 } from './2012.json';
export { default as year2013 } from './2013.json';
export { default as year2014 } from './2014.json';
export { default as year2015 } from './2015.json';
export { default as year2016 } from './2016.json';
export { default as year2017 } from './2017.json';
export { default as year2018 } from './2018.json';
export { default as year2019 } from './2019.json';

export { default as year2020 } from './2020.json';
export { default as year2021 } from './2021.json';
