import ModalMui from '@mui/material/Modal';
import Box from './Box';
import Icon from './Icon';
import { styled } from './lib';
import Flex from './Flex';

const Modal = ({ opened = false, onClose = () => {}, width, children, sx }) => {
  return (
    <ModalWrapper open={opened} onClose={onClose} width={width} sx={sx || {}}>
      <ModalContainer>
        <Box>
          {onClose ? (
            <ModalClose id="modal-close">
              <Box>
                <Icon aria-label="close" onClick={onClose} icon="close" />
              </Box>
            </ModalClose>
          ) : null}
          {children}
        </Box>
      </ModalContainer>
    </ModalWrapper>
  );
};

Modal.Header = ({ children, logo = null }) => (
  <ModalHeader>
    <ModalTitle>
      <Flex isCentered>
        {logo}
        <TitleText>{children}</TitleText>
      </Flex>
    </ModalTitle>
  </ModalHeader>
);

Modal.Content = ({ children }) => <ModalContent>{children}</ModalContent>;

Modal.Actions = ({ children, isCentered }) => (
  <ModalFooter>
    <Flex
      direction="column"
      spacing="10px"
      isCentered={isCentered}
      padding="2rem 12px"
    >
      {children}
    </Flex>
  </ModalFooter>
);

export default Modal;

const ModalWrapper = styled(ModalMui)`
  max-width: ${({ width }) => width || 'auto'};
  margin-left: auto;
  margin-right: auto;
`;
const ModalHeader = styled('header')`
  border-bottom: 1px solid ${({ theme }) => theme.color.backgrounds.borders};
`;
const ModalFooter = styled('footer')``;
const ModalTitle = styled('h3')`
  padding: 0 1rem;
`;
const TitleText = styled('span')`
  text-align: center;
  width: 100%;
  align-items: center;
`;

const ModalContainer = styled('div')`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  height: auto;
  max-height: 100%;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.color.backgrounds.white};
  border: 2px solid ${({ theme }) => theme.color.backgrounds.borders};
`;

const ModalClose = styled('div')`
  position: absolute;
  right: 1rem;
  top: 1.2rem;
  color: ${({ theme }) => theme.color.backgrounds.borders};
`;

const ModalContent = styled('section')`
  padding: 1.5rem 2rem;
`;
