import React from 'react';
import {
  Box,
  Image,
  Text,
  ColorPreview,
  Separator,
  IconCar,
} from '@drivably-apps/component-lib';
import styled from '@emotion/styled';
import {
  FetchUnReadNotificationsGql,
  NotificationUpdateReadGql,
} from '@portal/query/graphql';
import { useLocale, useMutation } from '@portal/hooks';
import { Route } from '@portal/lib';
import {
  Thumbnail,
  NotificationUnReadColor,
  generateNotificationTime,
  getVehicleName,
} from './lib';
import useNotifications from '@portal/vehicle/hooks/useNotifications';
import UploadIcon from '@portal/static/icons/upload_ready.svg';

const NotificationRow = ({ notification, showPanelNotification }) => {
  const { created_at, vehicle_id, read, vehicle = {}, id, file_url } = notification || {};
  const { locale } = useLocale();

  const { fetchNotifications } = useNotifications();

  const [updateNotificationRead] = useMutation(NotificationUpdateReadGql, {
    isSuccessAlertMuted: true,
    refetchQueries: [
      {
        query: FetchUnReadNotificationsGql,
      },
    ],
    onCompleted: () => {
      fetchNotifications();
    },
  });

  let isBUnotification = false;
  if (!vehicle && file_url?.length > 0) {
    isBUnotification = true;
  }

  const notificationTime = generateNotificationTime(created_at);
  const leadDetailPath = Route?.leadList.path;
  const redirectLink = isBUnotification ? file_url : `${leadDetailPath}/${vehicle_id}`;

  const handleNotificationClick = () => {
    if (!read) {
      const variables = {
        id: Number(id),
        read: true,
      };
      updateNotificationRead({ variables });
    }
    window.open(redirectLink, '_blank');
  };

  if (isBUnotification) {
    return (
      <>
        <Box paddingVertical="md" align="center" justify="space-between">
          <LinkBox flexGap="lg" align="center" onClick={handleNotificationClick}>
            <UploadIcon width={Thumbnail.width} height={Thumbnail.height} />
            <Box isFlex direction={showPanelNotification ? 'column' : 'row'}> 
              <Text
                weight="semibold"
                color="gray-text"
                marginLeft={!showPanelNotification ? 'xs' : ''}
              >
                {locale.notificationLeadsUploadComplete}
              </Text>
              <Text size="sm" style={{ color: 'red', marginLeft: !showPanelNotification ? 10 : 0 }} marginBottom="xs">
                {locale.notificationUploadFailed}
              </Text>
              {showPanelNotification && (
                <Text color="primary">{notificationTime}</Text>
              )}
            </Box>
          </LinkBox>
          <TimestampBox
            flexGap="xl"
            justify="flex-start"
            alignself={showPanelNotification}
          >
            {!showPanelNotification && (
              <Text color="primary">{notificationTime}</Text>
            )}
            {!read && <ColorPreview hex={NotificationUnReadColor} />}
          </TimestampBox>
        </Box>
        {!showPanelNotification && <Separator />}
      </>
    );  
  }

  const {
    customer_first_name,
    customer_last_name,
    year,
    make,
    model,
    trim,
    vehicle_images,
  } = vehicle;
  const name = `${customer_first_name} ${customer_last_name}`.trim();
  const notificationMsg = name
    ? `${name} ${locale.notificationPhotoUploadedFor}`
    : locale.notificationPhotosHaveBeenUploadedFor;

  const thumbnailURL = vehicle_images?.[0]?.link;
  const vehicleName = getVehicleName({ year, make, model, trim });

  return (
    <>
      <Box paddingVertical="md" align="center" justify="space-between">
        <LinkBox flexGap="lg" align="center" onClick={handleNotificationClick}>
          <Image
            hasError={!thumbnailURL}
            src={thumbnailURL}
            alt="Notification"
            placeholder={<IconCar />}
            width={Thumbnail.width}
            height={Thumbnail.height}
            radius="md"
          />
          <Box isFlex direction={showPanelNotification ? 'column' : 'row'}>
            <Text size="sm" color="gray-text" marginBottom="xs">
              {notificationMsg}
            </Text>
            <Text
              weight="semibold"
              color="gray-text"
              marginLeft={!showPanelNotification ? 'xs' : ''}
            >
              {vehicleName}
            </Text>
            {showPanelNotification && (
              <Text color="primary">{notificationTime}</Text>
            )}
          </Box>
        </LinkBox>
        <TimestampBox
          flexGap="xl"
          justify="flex-start"
          alignself={showPanelNotification}
        >
          {!showPanelNotification && (
            <Text color="primary">{notificationTime}</Text>
          )}
          {!read && <ColorPreview hex={NotificationUnReadColor} />}
        </TimestampBox>
      </Box>
      {!showPanelNotification && <Separator />}
    </>
  );
};

export default NotificationRow;

const LinkBox = styled(Box)`
  cursor: pointer;
`;

const TimestampBox = styled(Box)`
  ${({ alignself }) =>
    alignself &&
    `
    align-self: baseline;
  `}
`;
