import { useEffect } from 'react';

import { LoginRedirectRoute, RedirectPathKey } from '@portal/lib';
import { useLocalStorage } from './useLocalStorage';

/**
 * Handle authentication and authorization. Automatically redirect user to login
 * if not authenticated.
 */
export const useAuth = ({ isAuthenticatedRoute, token, saveToken }) => {
  const [redirectPath, setRedirectPath] = useLocalStorage('redirectPath', '');

  useEffect(() => {
    const url = new URL(window.location);
    const { searchParams } = url;
    const routerToken = searchParams.get('token');
    const userId = searchParams.get('user_id');

    // Save token passed as url parameter for 3rd party integrations (e.g. ACV)
    if (routerToken) {
      saveToken({
        token: routerToken,
        userId: userId,
      });

      searchParams.delete('token');
      searchParams.delete('user_id');

      window.location.replace(url.toString());
    }
  }, [token]);

  // Redirect to login page if token is not present
  const redirectToLogin = () => {
    // Save path to revert back after login
    if (isAuthenticatedRoute) {
      const path = encodeURIComponent(
        `${window.location?.pathname}${window.location.search}`
      );

      setRedirectPath(path);

      const newUrl = `${LoginRedirectRoute}?${RedirectPathKey}=${path}`;
      window.location.assign(newUrl);
    }
  };

  // Go to the page intended after securing auth
  const redirectToSavedPath = () => {
    const { searchParams } = new URL(window.location);
    const path = redirectPath || searchParams.get(RedirectPathKey);
    if (path) {
      setRedirectPath('');
      const newUrl = `${window.location.origin}${decodeURIComponent(path)}`;
      window.location.assign(newUrl);
    }
  };

  useEffect(() => {
    const shouldRequireAuth = typeof window !== 'undefined' && !token;

    if (shouldRequireAuth) {
      redirectToLogin();
    }
  }, [token]);

  return {
    redirectToLogin,
    redirectToSavedPath,
  };
};
