export { default as y1990 } from './1990.json';
export { default as y1991 } from './1991.json';
export { default as y1992 } from './1992.json';
export { default as y1993 } from './1993.json';
export { default as y1994 } from './1994.json';
export { default as y1995 } from './1995.json';
export { default as y1996 } from './1996.json';
export { default as y1997 } from './1997.json';
export { default as y1998 } from './1998.json';
export { default as y1999 } from './1999.json';

export { default as y2000 } from './2000.json';
export { default as y2001 } from './2001.json';
export { default as y2002 } from './2002.json';
export { default as y2003 } from './2003.json';
export { default as y2004 } from './2004.json';
export { default as y2005 } from './2005.json';
export { default as y2006 } from './2006.json';
export { default as y2007 } from './2007.json';
export { default as y2008 } from './2008.json';
export { default as y2009 } from './2009.json';

export { default as y2010 } from './2010.json';
export { default as y2011 } from './2011.json';
export { default as y2012 } from './2012.json';
export { default as y2013 } from './2013.json';
export { default as y2014 } from './2014.json';
export { default as y2015 } from './2015.json';
export { default as y2016 } from './2016.json';
export { default as y2017 } from './2017.json';
export { default as y2018 } from './2018.json';
export { default as y2019 } from './2019.json';

export { default as y2020 } from './2020.json';
export { default as y2021 } from './2021.json';
export { default as y2022 } from './2022.json';
export { default as y2023 } from './2023.json';
export { default as y2024 } from './2024.json';
export { default as y2025 } from './2025.json';
