import Text from './Text';

// Utility function to create preset text components with default props
const setTextPreset = (type, defaultProps = {}) => {
  return (props) => {
    // Combine the default props with the passed-in props, ensuring 'type' is passed
    return <Text {...defaultProps} {...props} type={type} />;
  };
};

// Define default props for headings
const HeadingDefaultProps = {
  color: 'secondary',
};

// Headings
export const H1 = setTextPreset('h1', HeadingDefaultProps);
export const H5 = setTextPreset('h5', HeadingDefaultProps);
export const H6 = setTextPreset('h6', HeadingDefaultProps);

// Body Texts
export const Overline = setTextPreset('overline', { isUpperCase: true });
