import {
  Box,
  Button,
  Separator,
  Text,
  useDetectDevice,
} from '@drivably-apps/component-lib';

import { formatDate, getOfferAmount } from '@portal/lib';

const LeadSelection = ({
  currentCustomer = {},
  customerFormatDate = 'DD/MM/YY',
  customerOffers = [],
  leadTitle,
  onClick,
}) => {
  const { isMobile } = useDetectDevice();
  return (
    <Box className="lead-selection">
      <Text headingType="h2" align="center">
        {leadTitle}
      </Text>
      <Box className="lead-selection--list" paddingTop="sm">
        {customerOffers?.map((customerOffer, index) => {
          const currentOffer = customerOffer.id === currentCustomer.id;
          const notLastOffer = index !== customerOffers.length - 1;
          const customFormatDate = formatDate(
            customerOffer.created_at,
            customerFormatDate
          );
          const date = customFormatDate.split('-')[0];
          const hour = customFormatDate.split('-').pop().trim();
          return (
            <>
              <Box
                align="center"
                className="lead-selection--list-item"
                isFlex
                key={index}
                justify="space-between"
                paddingHorizontal="md"
                paddingVertical="sm"
                direction={isMobile ? 'column' : 'row'}
              >
                <Box isFlex justify="center" direction="column" align="center">
                  <Text headingType="h3" marginBottom="xs">
                    {getOfferAmount(customerOffer.offer)}
                  </Text>
                  <Text size="xs">
                    {`Created at
                          ${date}`}
                  </Text>
                  <Text size="xs">{hour}</Text>
                </Box>
                <Button
                  isDisabled={currentOffer}
                  className={`lead-selection--list-button${
                    currentOffer && '-selected'
                  }`}
                  onClick={onClick.bind(this, customerOffer)}
                  variant={currentOffer ? 'contained' : 'outlined'}
                  {...(isMobile && { marginTop: 'md' })}
                >
                  {currentOffer ? 'Selected' : 'Select'}
                </Button>
              </Box>
              {notLastOffer && <Separator />}
            </>
          );
        })}
      </Box>
    </Box>
  );
};

export default LeadSelection;
