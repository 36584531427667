import { useMemo } from 'react';
import { useUserContext } from '@portal/app';
import {
  Flex,
  Layout,
  LogoACV,
  styled,
  SupportedTheme,
  Text,
} from '@portal/ui';
import { AcvAuctionsUrl, AppTheme } from '@portal/lib';
import { useLocale } from '@portal/hooks';
import AppHead from './AppHead';
import { useDetectDevice } from '@drivably-apps/component-lib';

const AppLayout = ({
  activePath,
  children,
  hasNavBar,
  isAuthenticated = false,
  isInitializing = false,
  isRedirectingToLogin = false,
  navigationItems = [],
  onLogoClick,
  onLogoutClick,
  onNavigationItemClick,
}) => {
  const { locale } = useLocale();
  const user = useUserContext();
  const { isMobile } = useDetectDevice();

  // Memoize the content rendering logic based on isInitializing, isRedirectingToLogin, and isAuthenticated
  const Content = useMemo(() => {
    // If initializing or redirecting, return null
    if (isInitializing || isRedirectingToLogin) return null;

    if (isAuthenticated) {
      // Generate the ACV menu items with memoization
      const acvMenuItems = [
        { text: locale.appAcvMarketplace, link: `${AcvAuctionsUrl}/search` },
        { text: locale.appAcvMyACV, link: `${AcvAuctionsUrl}/myacv` },
        {
          text: locale.appAcvMarketReport,
          link: `${AcvAuctionsUrl}/market-report`,
        },
        { text: locale.appAcvACVCapital, link: `${AcvAuctionsUrl}/capital` },
        { text: locale.appAcvClearCar, link: '/', forceIsActive: true },
      ];

      // If it's a mobile device, prioritize forceIsActive items
      if (isMobile) {
        acvMenuItems.sort(
          (a, b) => (b.forceIsActive ? 1 : 0) - (a.forceIsActive ? 1 : 0)
        );
      }

      // Memoize the ACV props
      const acvProps = AppTheme === SupportedTheme.Acv && {
        menuItems: acvMenuItems,
        cobrand: (
          <Flex
            marginLeft="6px"
            marginTop="6px"
            alignItems="center"
            alignSelf="flex-start"
          >
            <ACVLogoTextStyled>{locale.appAcvCobrandFrom}</ACVLogoTextStyled>
            <LogoACV
              path={AcvAuctionsUrl}
              width={isMobile ? 30 : 45}
              height={20}
            />
          </Flex>
        ),
      };

      const { currentStore } = user?.meta ?? {};

      return (
        <Layout
          {...acvProps}
          activePath={activePath}
          email={user.email}
          hasNavBar={hasNavBar}
          navigationItems={navigationItems}
          onLogoClick={onLogoClick}
          onLogoutClick={onLogoutClick}
          onNavigationItemClick={onNavigationItemClick}
          storeId={currentStore?.id}
          storeName={currentStore?.name}
          userName={user.name}
        >
          {children}
        </Layout>
      );
    }

    // Return children for non-authenticated users
    return children;
  }, [
    activePath,
    children,
    hasNavBar,
    isAuthenticated,
    isInitializing,
    isRedirectingToLogin,
    locale,
    isMobile,
    navigationItems,
    onLogoClick,
    onLogoutClick,
    onNavigationItemClick,
    user,
  ]);

  return (
    <>
      <AppHead />
      {Content}
    </>
  );
};

export default AppLayout;

const ACVLogoTextStyled = styled(Text)`
  ${({ theme }) => theme.typography.raw.menuText};
  color: ${({ theme }) => theme.color.GlobalText.tertiaryColor};
`;
