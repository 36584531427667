import { gql } from '@apollo/client';

export const NotificationsPollingGql = gql`
  query NotificationsFetch {
    notifications_aggregate(where: { read: { _eq: false } }) {
      aggregate {
        count
      }
    }
    notifications(order_by: { id: desc }, limit: 10) {
      id
      created_at
      read
      vehicle_id
      file_url
      vehicle {
        make
        model
        style
        trim
        year
        customer_first_name
        customer_last_name
        vehicle_images(order_by: { id: desc }, limit: 1) {
          link
        }
      }
    }
  }
`;

export const FetchNotificationsGql = gql`
  query NotificationsFetchAll($limit: Int = 10, $offset: Int = 0) {
    notifications_aggregate {
      aggregate {
        count
      }
    }
    notifications(
      order_by: { created_at: desc }
      limit: $limit
      offset: $offset
    ) {
      id
      created_at
      read
      vehicle_id
      file_url
      vehicle {
        make
        model
        style
        trim
        year
        customer_first_name
        customer_last_name
        vehicle_images(order_by: { id: desc }, limit: 1) {
          link
        }
      }
    }
  }
`;

export const FetchUnReadNotificationsGql = gql`
  query NotificationsFetchUnRead {
    notifications(where: { read: { _eq: false } }) {
      id
    }
  }
`;

export const NotificationUpdateReadGql = gql`
  mutation NotificationUpdateRead($id: Int!, $read: Boolean!) {
    update_notifications_by_pk(pk_columns: { id: $id }, _set: { read: $read }) {
      id
      read
    }
  }
`;

export const NotificationUpdateReadByVehicleIdGql = gql`
  mutation NotificationUpdateReadByVehicleId($id: Int!) {
    update_notifications(
      _set: { read: true }
      where: { vehicle_id: { _eq: $id }, read: { _eq: false } }
    ) {
      returning {
        vehicle_id
        read
      }
    }
  }
`;

export const NotificationUpdateMarkAllReadGql = gql`
  mutation UpdateNotificationMarkAllRead($unreadIds: [Int!]!) {
    update_notifications(
      _set: { read: true }
      where: { id: { _in: $unreadIds } }
    ) {
      returning {
        id
        read
      }
    }
  }
`;
