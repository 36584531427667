import { Box, Button, Label } from '@drivably-apps/component-lib';

import { Icon, Tooltip } from '@portal/ui';
import { styled } from '@portal/ui/lib';

const SectionCard = ({
  buttonText = '',
  buttonIconName = '',
  buttonProps = {},
  cardHeader = '',
  children = [],
  handleClick = () => {},
  headerStyle = {},
  isButtonDisabled = false,
  childPadding = '',
  showToolTip = false,
  toolTipTitle = '',
}) => {
  return (
    <CardContainer>
      <HeaderContainer
        justify="space-between"
        align="center"
        paddingRight="lg"
        paddingVertical="sm"
      >
        {cardHeader && (
          <Label margin="lg" weight="semibold" {...headerStyle}>
            {cardHeader}
          </Label>
        )}
        {buttonText && showToolTip ? (
          <Tooltip title={toolTipTitle} size="xs">
            <HeaderButton
              isCapitalCase
              isDisabled={isButtonDisabled}
              isRounded
              onClick={handleClick}
              size="xs"
            >
              {buttonIconName && (
                <Icon
                  icon={buttonIconName}
                  color="#FFF"
                  size="md2"
                  marginRight="4px"
                  marginBottom="2px"
                />
              )}
              {buttonText}
            </HeaderButton>
          </Tooltip>
        ) : (
          buttonText && (
            <HeaderButton
              isCapitalCase
              isDisabled={isButtonDisabled}
              isRounded
              onClick={handleClick}
              size="xs"
              {...buttonProps}
            >
              {buttonIconName && (
                <Icon
                  icon={buttonIconName}
                  color="#FFF"
                  size="md2"
                  marginRight="4px"
                  marginBottom="2px"
                />
              )}
              {buttonText}
            </HeaderButton>
          )
        )}
      </HeaderContainer>
      <Box padding={childPadding || 'xl'}>{children}</Box>
    </CardContainer>
  );
};

export default SectionCard;

const CardContainer = styled(Box)`
  border: 1px solid ${({ theme }) => theme.color.ACVBgLm.background3Color};
  border-radius: 8px;
  background-color: ${({ theme }) => theme.color.backgrounds.white};
`;

const HeaderContainer = styled(Box)`
  border-bottom: 1px solid
    ${({ theme }) => theme.color.ACVBgLm.background3Color};
  background-color: ${({ theme }) => theme.color.raw.gray2};
  border-radius: 8px 8px 0 0;
`;

const HeaderButton = styled(Button)`
  font-weight: 500 !important;
`;
