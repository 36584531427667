import { Field } from 'formik';
import { Box, Radio, Text } from '@drivably-apps/component-lib';

const RadioField = ({ text, ...props }) => (
  <Box
    isFlex
    justify="space-between"
    direction="row"
    align="center"
    flexGap="sm"
  >
    <Text>{text}</Text>

    <Field type="radio" {...props}>
      {({ field }) => <Radio {...field} isChecked={field.checked} />}
    </Field>
  </Box>
);

export default RadioField;
