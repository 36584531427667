import { AnalyticsIsEnabled, AnalyticsApiKey } from './config';

const GTMSrcID = 'clctrd-portal-gtm-script';
const GTMFrameID = 'clctrd-portal-gtm-iframe';

/* Custom events on the app. */
export const Event = {
  ClickVehicle: 'click_vehicle',
  CloseFilter: 'close_filter',
  ClosePhotoGallery: 'close_photo_gallery',
  LeaveDamageReportSection: 'leave_damage_report_section',
  OpenPhotoGallery: 'open_photo_gallery',
  ReconEstimateUpdated: 'recon_estimate_updated',
  Search: 'search',
  SendDealerWalkaroundSMS: 'send_dealer_walkaround_sms',
  SendInspectionSMS: 'send_inspection_sms',
  ViewPage: 'view_page',
  VisitDamageReportSection: 'visit_damage_report_section',
};

/** Initialize analytics API */
export const atsInit = () => {
  if (AnalyticsIsEnabled) {
    // Add GTM script
    if (!document.querySelector(`#${GTMSrcID}`)) {
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });

        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer' ? '&l=' + l : '';

        j.id = GTMSrcID;
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;

        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', AnalyticsApiKey);
    }

    // Add GTM iframe
    if (!document.querySelector(`#${GTMFrameID}`) && AnalyticsApiKey) {
      const iframe = document.createElement('iframe');

      iframe.id = GTMFrameID;
      iframe.src = `https://www.googletagmanager.com/ns.html?id=${AnalyticsApiKey}`;
      iframe.height = '0';
      iframe.width = '0';
      iframe.style = 'display:none;visibility:hidden';

      document.body.prepend(iframe);
    }
  }
};

/**
 * Trigger custom events
 *
 * @param {string} event - Custom event to fire (must an Event enum)
 * @param {object} data - Additional event data. Must define the properties
 *   passed here in GTM's data layer. See `/docs/analytics.md` for more
 *   details.
 */
export const atsFireEvent = (event, data = {}) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ...data, event });
};
